import NotificationAdd from "./../views/notifications/components/NotificationAdd.vue";
import NotificationAddClass from "./../models/notifications/NotificationAddClass";
import { USER_TYPE, PAGE_GO_TO_TOKEN } from "./../utils/constantLists";
import { LANGUAGES } from "./../utils/constants";
import { getLanguage } from "./../utils/functions";
import i18n from "./../i18n";

export default {
  components: {
    NotificationAdd,
  },
  data() {
    return {
      appLang: getLanguage(),
      notificationAddClass: new NotificationAddClass(),
    };
  },
  methods: {
    async setAddNotification(data, setFor) {
      this.notificationAddClass.setFor = setFor;

      switch (setFor) {
        case this.notificationAddClass.setForUser:
          this.notificationAddClass.userTypeToken = data.userTypeToken;
          await this.setUserModelNameNotification(data.userTypeToken);
          await this.setUsersListNotification([data.userToken]);
          break;
        case this.notificationAddClass.setForGroup:
          this.notificationAddClass.userTypeToken = USER_TYPE.Student;
          this.notificationAddClass.modelName = "students";
          this.notificationAddClass.itemToken = data.educationalGroupToken;
          this.notificationAddClass.pageGoToToken =
            PAGE_GO_TO_TOKEN.EducationalGroups;
          this.notificationAddClass.educationalGroupToken =
            data.educationalGroupToken;
          break;
        case this.notificationAddClass.setForTime:
          this.notificationAddClass.userTypeToken = USER_TYPE.Student;
          this.notificationAddClass.modelName = "students";
          this.notificationAddClass.itemToken =
            data.educationalScheduleTimeToken;
          this.notificationAddClass.pageGoToToken =
            PAGE_GO_TO_TOKEN.EducationalScheduleTimes;
          this.notificationAddClass.educationalGroupToken =
            data.educationalGroupToken;
          break;
        case this.notificationAddClass.setForTimeWithData:
          this.notificationAddClass.userTypeToken = USER_TYPE.Student;
          this.notificationAddClass.modelName = "students";
          this.notificationAddClass.itemToken =
            data.educationalScheduleTimeToken;
          this.notificationAddClass.pageGoToToken =
            PAGE_GO_TO_TOKEN.EducationalScheduleTimes;
          this.notificationAddClass.educationalGroupToken =
            data.educationalGroupToken;

          if (this.appLang === LANGUAGES.arEG) {
            // title
            this.notificationAddClass.titleAr = `${i18n.t(
              "notifications.alertYouByTimeOfSubject"
            )} (${
              data.educationalGroupDataDB.educationalCategoryData
                .educationalCategoryNameAr
            }) \n ${i18n.t("notifications.inGroup")} (${
              data.educationalGroupDataDB.educationalGroupNameAr
            })`;
            // body
            this.notificationAddClass.bodyAr = `${i18n.t(
              "notifications.startIn"
            )} (${data.dateTimeFromDate}) \n ${i18n.t(
              "notifications.startAt"
            )} (${data.dateTimeFromTime}) \n ${i18n.t(
              "notifications.comeBefoeTen"
            )}`;
          } else if (this.appLang === LANGUAGES.enUS) {
            this.notificationAddClass.titleEn = `${i18n.t(
              "notifications.alertYouByTimeOfSubject"
            )} (${
              data.educationalGroupDataDB.educationalCategoryData
                .educationalCategoryNameEn
            }) ${i18n.t("notifications.inGroup")} (${
              data.educationalGroupDataDB.educationalGroupNameEn
            })`;
            this.notificationAddClass.bodyEn = `${i18n.t(
              "notifications.startIn"
            )} (${data.dateTimeFromDate}) \n  ${i18n.t(
              "notifications.startAt"
            )} (${data.dateTimeFromTime}) \n ${i18n.t(
              "notifications.comeBefoeTen"
            )}`;
          }
          break;
        case this.notificationAddClass.setForGeneral:
          this.notificationAddClass.userTypeToken = USER_TYPE.Student;
          this.notificationAddClass.modelName = "students";
          break;

        default:
          break;
      }
    },

    async setUserModelNameNotification(userTypeToken) {
      switch (userTypeToken) {
        case USER_TYPE.Student:
          this.notificationAddClass.modelName = "students";
          break;
        case USER_TYPE.Employee:
          this.notificationAddClass.modelName = "employees";
          break;
        case USER_TYPE.MasterAdmin:
          this.notificationAddClass.modelName = "masterAdmins";
          break;

        default:
          break;
      }
    },
    async setUsersListNotification(usersTokenList) {
      this.notificationAddClass.usersList = usersTokenList;
    },
  },
};
