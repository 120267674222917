<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <form autocomplete="off">
      <div class="my-card">
        <div class="row">
          <!-- setForGeneral -->
          <template v-if="notification.setFor == notification.setForGeneral">
            <CustomSelectBox
              :className="'col-md-12'"
              :id="'userTypeToken'"
              :value="notification.userTypeToken"
              :options="userTypeTokenOptions"
              v-on:changeValue="
                notification.userTypeToken = $event;
                getUsersDialog();
              "
              :title="$t('Users.selectType')"
              :imgName="'type.svg'"
            />
            <CustomSelectBoxMultiple
              :className="'col-md-12'"
              :id="`${id}-usersList`"
              :value="notification.usersList"
              :options="studentTokensListOptions"
              v-on:changeValue="notification.usersList = $event"
              :title="$t('Users.select')"
              :imgName="'users.svg'"
              :returnArrayOfObjects="false"
            />
          </template>
          <!-- setForUser -->
          <template v-else-if="notification.setFor == notification.setForUser">
            <CustomSelectBoxMultiple
              :className="'col-md-12'"
              :id="`${id}-usersList`"
              :value="notification.usersList"
              :options="studentTokensListOptions"
              v-on:changeValue="notification.usersList = $event"
              :title="$t('Users.select')"
              :imgName="'users.svg'"
              :returnArrayOfObjects="false"
            />
          </template>
          <!-- Group || Time -->
          <template v-else>
            <CustomSelectBoxMultiple
              class="mt-10"
              :className="'col-md-12'"
              :id="`${id}-studentsList`"
              :value="notification.studentsList"
              :options="studentTokensListOptions"
              v-on:changeValue="notification.studentsList = $event"
              :title="$t('students.select')"
              :imgName="'students.svg'"
              :returnArrayOfObjects="false"
            />
            <CustomSelectBoxMultiple
              class="mt-10"
              :className="'col-md-12'"
              :id="`${id}-employeesList`"
              :value="notification.employeesList"
              :options="employeeTokensOptions"
              v-on:changeValue="notification.employeesList = $event"
              :title="$t('employees.select')"
              :imgName="'employees.svg'"
              :returnArrayOfObjects="false"
            />
          </template>

          <!-- comman data -->
          <template>
            <CustomInput
              :className="'col-md-6'"
              :id="`${id}-titleAr`"
              :errors="errors_titleAr"
              :value="notification.titleAr"
              v-on:changeValue="
                notification.titleAr = $event;
                $v.notification.titleAr.$touch();
              "
              :title="$t('general.titleAr')"
              :imgName="'notifications.svg'"
            />
            <CustomInput
              :className="'col-md-6'"
              :id="`${id}-titleEn`"
              :errors="errors_titleEn"
              :value="notification.titleEn"
              v-on:changeValue="
                notification.titleEn = $event;
                $v.notification.titleEn.$touch();
              "
              :title="$t('general.titleEn')"
              :imgName="'notifications.svg'"
            />
            <CustomInput
              :className="'col-md-6'"
              :id="`${id}-titleUnd`"
              :value="notification.titleUnd"
              v-on:changeValue="notification.titleUnd = $event"
              :title="$t('general.titleUnd')"
              :imgName="'notifications.svg'"
            />
            <TextArea
              :className="'col-md-6'"
              :id="`${id}-bodyAr`"
              :errors="errors_bodyAr"
              :value="notification.bodyAr"
              v-on:changeValue="
                notification.bodyAr = $event;
                $v.notification.bodyAr.$touch();
              "
              :title="$t('general.bodyAr')"
              :imgName="'description.svg'"
            />
            <TextArea
              :className="'col-md-6'"
              :id="`${id}-bodyEn`"
              :errors="errors_bodyEn"
              :value="notification.bodyEn"
              v-on:changeValue="
                notification.bodyEn = $event;
                $v.notification.bodyEn.$touch();
              "
              :title="$t('general.bodyEn')"
              :imgName="'description.svg'"
            />
            <TextArea
              :className="'col-md-6'"
              :id="`${id}-bodyUnd`"
              :value="notification.bodyUnd"
              v-on:changeValue="notification.bodyUnd = $event"
              :title="$t('general.bodyUnd')"
              :imgName="'description.svg'"
            />
          </template>
        </div>
      </div>

      <div class="form-actions">
        <div class="icon-submit" @click.prevent="submitForm">
          <img src="@/assets/images/check-icon.svg" :title="submitName" />
        </div>
        <div
          @click.prevent
          class="icon-cancel"
          v-b-modal="`ConfirmCloseSheet-${bottomSheetName}`"
        >
          <img src="@/assets/images/cancel-icon.svg" :title="$t('cancel')" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {
  getLanguage,
  fullPathFileFromServer,
  bottomSheetScrollToTop,
} from "./../../../utils/functions";

import {
  isValidNameAr,
  isValidNameEn,
  isValidTextAr,
  isValidTextEn,
} from "./../../../utils/validationHelper";
import CustomInput from "./../../../components/general/CustomInput.vue";
import CustomSelectBox from "./../../../components/general/CustomSelectBox.vue";
import CustomSelectBoxMultiple from "./../../../components/general/CustomSelectBoxMultiple.vue";
import TextArea from "./../../../components/general/TextArea.vue";
import { checkPrivilege } from "./../../../utils/functions";
import generalMixin from "./../../../utils/generalMixin";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import {
  getUsersDialog,
  getEducationalGroupStudentsDialog,
  getEducationalGroupEmployeesDialog,
} from "./../../../utils/dialogsOfApi";
import { getDialogOfUserTypes } from "./../../../utils/dialogsOfConstantsLists";
import studentsImg from "@/assets/images/students.svg";
import employeesImg from "@/assets/images/employees.svg";
import PreLoader from "@/components/general/PreLoader.vue";

export default {
  mixins: [generalMixin, validationMixin],
  components: {
    PreLoader,
    CustomInput,
    CustomSelectBox,
    CustomSelectBoxMultiple,
    TextArea,
  },
  data() {
    return {
      language: getLanguage(),
      isLoading: false,
      studentsImg,
      employeesImg,
      studentTokensListOptions: [],
      employeeTokensOptions: [],
      userTypeTokenOptions: getDialogOfUserTypes(),
    };
  },
  props: {
    notification: {
      type: Object,
    },
    submitName: {
      type: String,
    },
    id: {
      type: String,
      default: "id",
    },
    deleteFileStatus: {
      type: Boolean,
      default: false,
    },
    bottomSheetName: {
      type: String,
      default: "",
    },
  },
  validations: {
    notification: {
      titleAr: { required, isValidNameAr },
      titleEn: { required, isValidNameEn },
      bodyAr: { required, isValidTextAr },
      bodyEn: { required, isValidTextEn },
    },
  },
  computed: {
    errors_titleAr() {
      let errors = [];
      if (this.$v.notification.titleAr.$error) {
        if (
          !this.$v.notification.titleAr.required &&
          !this.$v.notification.titleEn.required
        )
          errors.push(this.$t("validation.fieldIsRequired"));
        else if (
          this.$v.notification.titleAr.required &&
          !this.$v.notification.titleAr.isValidNameAr
        )
          errors.push(this.$t("validation.nameIsRequiredInAr"));
      }
      return errors;
    },
    errors_titleEn() {
      let errors = [];
      if (this.$v.notification.titleEn.$error) {
        if (
          !this.$v.notification.titleAr.required &&
          !this.$v.notification.titleEn.required
        )
          errors.push(this.$t("validation.fieldIsRequired"));
        else if (
          this.$v.notification.titleEn.required &&
          !this.$v.notification.titleEn.isValidNameEn
        )
          errors.push(this.$t("validation.nameIsRequiredInEn"));
      }
      return errors;
    },
    errors_bodyAr() {
      let errors = [];
      if (this.$v.notification.bodyAr.$error) {
        if (
          !this.$v.notification.bodyAr.required &&
          !this.$v.notification.bodyEn.required
        )
          errors.push(this.$t("validation.fieldIsRequired"));
        else if (
          this.$v.notification.bodyAr.required &&
          !this.$v.notification.bodyAr.isValidTextAr
        )
          errors.push(this.$t("validation.nameIsRequiredInAr"));
      }
      return errors;
    },
    errors_bodyEn() {
      let errors = [];
      if (this.$v.notification.bodyEn.$error) {
        if (
          !this.$v.notification.bodyAr.required &&
          !this.$v.notification.bodyEn.required
        )
          errors.push(this.$t("validation.fieldIsRequired"));
        else if (
          this.$v.notification.bodyEn.required &&
          !this.$v.notification.bodyEn.isValidTextEn
        )
          errors.push(this.$t("validation.nameIsRequiredInEn"));
      }
      return errors;
    },
  },
  watch: {
    "notification.modelName": function () {
      this.getUsersDialog();
    },
    "notification.userTypeToken": function () {
      this.getUsersDialog();
    },
    "notification.setFor": function () {
      this.getEducationalGroupEmployeesDialog();
    },
  },
  methods: {
    async getUsersDialog() {
      this.isLoading = true;
      if (
        this.notification.setFor == this.notification.setForUser ||
        this.notification.setFor == this.notification.setForGeneral
      ) {
        let params = {
          modelName: this.notification.modelName,
          userTypeToken: this.notification.userTypeToken,
        };
        this.studentTokensListOptions = await getUsersDialog(params);
      } else {
        let params = {
          educationalGroupToken: this.notification.educationalGroupToken,
        };
        this.studentTokensListOptions = await getEducationalGroupStudentsDialog(
          params
        );
      }

      this.isLoading = false;
    },
    async getEducationalGroupEmployeesDialog() {
      this.isLoading = true;
      let params = {
        educationalGroupToken: this.notification.educationalGroupToken,
      };
      this.employeeTokensOptions = await getEducationalGroupEmployeesDialog(
        params
      );
      this.isLoading = false;
    },

    async submitForm() {
      bottomSheetScrollToTop();
      this.$v.$touch();
      if (
        ((this.$v.notification.titleAr.required &&
          this.$v.notification.titleAr.isValidNameAr) ||
          (this.$v.notification.titleEn.required &&
            this.$v.notification.titleEn.isValidNameEn)) &&
        ((this.$v.notification.bodyAr.required &&
          this.$v.notification.bodyAr.isValidTextAr) ||
          (this.$v.notification.bodyEn.required &&
            this.$v.notification.bodyEn.isValidTextEn))
      ) {
        if (
          this.notification.setFor == this.notification.setForGroup ||
          this.notification.setFor == this.notification.setForTime ||
          this.notification.setFor == this.notification.setForTimeWithData
        ) {
          this.notification.usersList = [
            ...this.notification.studentsList,
            ...this.notification.employeesList,
          ];
        }

        this.$emit("submitForm");
      }
    },
    checkPrivilege,
    fullPathFileFromServer,
  },
  created() {
    if (this.notification.modelName && this.notification.userTypeToken) {
      this.getUsersDialog();
    }
    if (this.notification.setFor) {
      this.getEducationalGroupEmployeesDialog();
    }
  },
};
</script>
